<script setup>
import { Head, Link, useForm } from "@inertiajs/vue3"
import AuthLayout from "@/Dashboard/Layouts/AuthLayout.vue"
import route from "ziggy-js"
import { ref } from "vue"
import { MDBInput, MDBCheckbox } from "mdb-vue-ui-kit"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

const initVars = JSON.parse(atob(window.efboot))

defineOptions({ layout: AuthLayout })

defineProps({
	canResetPassword: {
		type: Boolean,
		default: false,
	},
	status: {
		type: String,
		default: "",
	},
})

const form = useForm({
	email: "",
	password: "",
	remember: false,
})
const showPassword = ref(false)

const submit = () => {
	form
		.transform((data) => ({
			...data,
			email: data.email.toLowerCase().trim(),
			remember: form.remember ? "on" : "",
		}))
		.post(route("login"), {
			onFinish: () => form.reset("password"),
		})
}
</script>

<template>
	<Head title="Log in" />

	<section>
		<div
			class="container-fluid text-center text-lg-start vh-100 overflow-auto overflow-lg-hidden p-0"
		>
			<div class="align-items-center d-flex flex-column flex-lg-row vh-100">
				<div
					class="col-lg py-5 d-lg-flex align-items-center justify-content-center"
					style="z-index: 10"
				>
					<div class="d-flex justify-content-center">
						<div class="text-center p-lg-5">
							<picture>
								<source
									type="image/webp"
									:srcset="initVars.theme_large_logo_set"
								/>
								<img
									class="platform-login-logo"
									:src="initVars.theme_large_logo_path"
									:alt="initVars.app_name"
								/>
							</picture>
						</div>
					</div>
				</div>
				<div
					class="form col-lg overflow-lg-y-auto d-flex flex-column align-items-stretch mx-4 mx-lg-0"
				>
					<div class="card bg-glass py-0 shadow px-sm-4 px-md-5">
						<div class="card-body d-lg-flex align-items-center flex-column">
							<!-- Form Goes HERE -->
							<form
								class="flex-fill d-lg-flex flex-column justify-content-center w-100"
								@submit.prevent="submit"
							>
								<h2 class="text-center fw-bold text-lg-start text-black">
									Welcome back!
								</h2>
								<p class="text-center text-lg-start mb-4 text-black">
									Please enter your information to log into your account.
								</p>

								<MDBInput
									id="email"
									v-model="form.email"
									type="email"
									required
									autofocus
									autocomplete="username"
									label="Email address"
									:form-outline="false"
									wrapper-class="form-floating auth-field email-field"
									:is-validated="!!form.errors.email"
									:is-valid="!form.errors.email"
									:invalid-feedback="form.errors.email"
								/>
								<MDBInput
									id="password"
									v-model="form.password"
									:type="showPassword ? 'text' : 'password'"
									class="mt-1"
									required
									autocomplete="current-password"
									label="Password"
									:form-outline="false"
									wrapper-class="form-floating auth-field password-field"
									:is-validated="!!form.errors.password"
									:is-valid="!form.errors.password"
									:invalid-feedback="form.errors.password"
								>
									<div class="trailing" @click="showPassword = !showPassword">
										<FontAwesomeIcon
											v-if="showPassword"
											:icon="lookupIcon('eye', 'fal')"
										/>
										<FontAwesomeIcon
											v-else
											:icon="lookupIcon('eye-slash', 'fal')"
										/>
									</div>
								</MDBInput>

								<div class="row pb-3">
									<div class="col text-start text-black">
										<MDBCheckbox
											v-model="form.remember"
											label="Remember me"
											wrapper-class="mb-3 mb-md-0"
											checked
										/>
									</div>
									<div class="col text-end">
										<Link
											:href="route('password.request')"
											class="forgot-password small text-primary fw-bolder"
										>
											Forgot your password?
										</Link>
									</div>
								</div>
								<div class="mb-3 text-center">
									<button
										class="btn btn-primary rounded-pill px-5"
										type="submit"
										:disabled="form.processing"
									>
										Log In
										<FontAwesomeIcon :icon="lookupIcon('arrow-right', 'fas')" />
									</button>
								</div>
								<p class="mb-3 text-center text-black">
									Don't have an account yet?
									<Link
										:href="route('register')"
										class="register-link text-primary fw-bolder"
									>
										Sign Up!
									</Link>
								</p>
							</form>
							<p class="mb-3 text-center w-100">
								<a
									href="https://www.gramercytech.com/terms-of-service"
									target="_blank"
									class="small text-black me-2 fw-bolder"
									>Terms of Service</a
								>
								|
								<a
									href="https://www.gramercytech.com/privacy-policy"
									target="_blank"
									class="small text-black ms-2 fw-bolder"
									>Privacy Policy</a
								>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<style scoped>
:deep(div.form-helper) {
	float: right !important;
	color: rgba(0, 0, 0, 0.6);
}
:deep(.is-invalid ~ .form-helper) {
	margin-top: -1rem;
}
.trailing {
	color: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 4px;
	right: 10px;
	opacity: 0.65;
}
:deep(input:user-invalid) {
	border-color: #dc4c64;
}
:deep(input:user-valid) {
	border-color: #28a745;
}
@media (max-width: 992px) {
	.platform-login-logo {
		height: 177px;
		width: auto;
	}
}
</style>
